.owm {
    .checkbox {
        position: initial;
        margin: 0;
        padding: 4px 0;

        > *:not(input) {
            display: inline-block;
            vertical-align: middle;
        }

        > *:not(input):not(.checkbox__label) {
            // Must be here, not in the .checkbox__icon, otherwise the
            // vertical align breaks
            font-size: 18px;
            position: absolute;

            @media (min-width: $screen-sm-min) {
                font-size: 16px;
            }
        }

        .checkbox__icon {
            cursor: pointer;
            color: $checkbox-color;

            &--checked {
                color: $checkbox-checked-color;
            }
        }

        .checkbox__label {
            cursor: pointer;
            margin-left: 25px;
        }

        > input[type=checkbox] {
            width: 0;
            height: 0;
            float: left;
            display: block;
            visibility: hidden;

            &[disabled] + * .checkbox__icon {
                opacity: 0.5;
                cursor: not-allowed;
            }

            &:focus + * .checkbox__icon {
                color: $checkbox-hover-color;
            }
        }
    }

    .form {
        &--read-only {
            .form__label, .form__value {
                margin-bottom: 0;
            }

            .form__value {
                @media (min-width: $screen-sm-min) {
                    padding: 4px 0;
                }
            }
        }

        &__line {
            @include clearfix;

            &--hidden {
                display: none;
            }

            &--overflow-visible {
                .form__value {
                    overflow: visible;
                }
            }
        }

        &__label {
            float: left;
            width: 33.3%;
            padding-right: 20px;
            // Define margin here, not on the line, to not break the transition
            margin-bottom: 10px;
            font-weight: 600;
            min-height: 1px;
            box-sizing: border-box;
            white-space: nowrap;

            @media (max-width: $screen-xs-max) {
                line-height: 36px;
            }

            &__text {
                display: block;
                padding: 4px 0;
                max-width: 100%;
                font-weight: inherit;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &__value {
            float: left;
            width: 66.6%;
            min-height: 36px;
            line-height: 36px;
            margin-bottom: 10px;
            // Cut long text that cannot be wrapped
            overflow: hidden;

            @media (min-width: $screen-sm-min) {
                min-height: 20px;
                line-height: 20px;
            }

            label {
                font-weight: normal;
            }

            img {
                max-width: 100%;
            }

            &--offset {
                margin-left: 33%;
            }

            &__text-control {
                // Maintain same row height when rows contain just text
                padding: 4px 0;
            }

            &__line {
                position: relative;
                margin-bottom: 10px;
                min-height: 36px;

                @media (min-width: $screen-sm-min) {
                    min-height: 20px;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .help-block {
                margin-bottom: 0;
            }
        }

        @mixin vertical_form {
            .form__line:not(.form__line--checkbox) {
                .form__label {
                    float: none;
                    width: auto;
                    margin-bottom: 0;
                    padding-right: 0;
                }

                .form__value {
                    float: none;
                    width: auto;
                    // A bit more space between values and subsequent labels
                    // in the vertical layout
                    margin-bottom: 20px;
                }
            }

            .form__line.form__line--checkbox {
                .form__label {
                    // Make a bit wider on narrow screens so that long labels
                    // are fully readable
                    width: 45%;
                }

                .form__value {
                    width: 55%;
                }
            }
        }

        &--vertical {
            @include vertical_form;
        }

        &--xs-vertical {
            @media (max-width: $screen-xs-max) {
                @include vertical_form;
            }
        }

        &--sm-vertical {
            @media (max-width: $screen-sm-max) {
                @include vertical_form;
            }
        }

        &--md-vertical {
            @media (max-width: $screen-md-max) {
                @include vertical_form;
            }
        }

        &__error {
            position: relative;
            padding-left: 15px;
            color: $error-color;
            line-height: $line-height-base;

            @media (max-width: $screen-sm-max) {
                margin-top: 5px;
            }

            app-icon, .icon {
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .app-select.loading ~ .app-form-error {
            display: none;
        }

        .fieldset-heading {
            margin: 20px 0 20px;
            position: relative;
            display: block;

            &:first-child {
                margin-top: 0;
            }

            &__text {
                background: white;
                padding-right: 10px;
                position: relative;
                display: inline-block;
                color: $fieldset-heading-color;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: 100;
            }

            &:before {
                content: "";
                display: block;
                position: absolute;
                border-bottom: 1px solid $input-border;
                left: 10px;
                right: 0;
                top: 11px;
            }
        }

        input[type="text"],
        input[type="url"],
        input[type="password"],
        input[type="email"],
        input[type="tel"],
        input[type="number"],
        input[type="date"],
        textarea {
            box-shadow: none;
            padding: 4px 8px;
            height: auto;
            line-height: 35px;
            background: $input-bg;
            border: 1px solid $input-border;
            // border radius controlled through bootstrap variable in order
            // to make input groups with addons work
            color: inherit;
            @include transition(box-shadow $input-transition);

            @media (min-width: $screen-sm-min) {
                line-height: inherit;
            }

            &:focus {
                box-shadow: $input-shadow-focus;
                outline: none;
            }

            &.name {
                font-size: 16px;
                font-weight: 600;
            }

            &.input-label {
                font-weight: 600;
            }

            &[disabled] {
                color: $input-color-disabled;
                cursor: not-allowed;
            }
        }

        // The ng- classes are managed by Angular
        // The non ng- classes can be set by the developer
        .ng-dirty.ng-invalid:not(.form__collection),
        .ng-dirty.invalid:not(.form__collection),
        .dirty.ng-invalid:not(.form__collection),
        .dirty.invalid:not(.form__collection) {
            &, > .input-group {
                &, // if the classes are placed directly on the input (reset password form)
                > input[type="text"],
                > input[type="url"],
                > input[type="password"],
                > input[type="email"],
                > input[type="tel"],
                > input[type="number"],
                > input[type="date"],
                > textarea,
                > .select2 > .selection > .select2-selection--single,
                > .select2 > .selection > .select2-selection--multiple {
                    border-color: $input-border-invalid;
                }
            }
        }

        .textarea--fit-content {
            overflow: hidden;
            resize: none;
        }

        .select2-container--default {
            .select2-selection--single {
                box-shadow: none;
                border: 1px solid $input-border;
                padding: 4px 8px;
                height: 36px;
                cursor: pointer;
                border-radius: $input-border-radius;
                @include transition(box-shadow $input-transition);

                @media (min-width: $screen-sm-min) {
                    height: 30px;
                }

                &:focus {
                    box-shadow: $input-shadow-focus;
                    outline: none;
                }

                .select2-selection__rendered {
                    color: inherit;
                    line-height: 35px;
                    padding-left: 0;
                    padding-right: 24px;

                    &:focus {
                        outline: none;
                    }

                    .select2-selection__placeholder {
                        color: $placeholder-color;
                    }

                    @media (min-width: $screen-sm-min) {
                        line-height: 19px;
                    }
                }

                .select2-selection__arrow {
                    height: 34px;

                    b {
                        border-top-color: #333;
                    }

                    @media (min-width: $screen-sm-min) {
                        width: 24px;
                        height: 28px;
                        background: $input-arrow-bg;
                        border-top-right-radius: $input-border;
                        border-bottom-right-radius: $input-border;
                    }
                }
            }

            .select2-selection--multiple {
                background-color: #fff;
                border: none;
                border: 1px solid $input-border;
                border-radius: $input-border-radius;
                padding: 4px 8px;
                cursor: text;
                min-height: 0;
                @include transition(box-shadow $input-transition);

                &:focus {
                    box-shadow: $input-shadow-focus;
                    outline: none;
                }

                .select2-selection__rendered {
                    display: block;
                    padding: 0;
                    // subtract the 5px of the labels
                    margin: 0 -9px -5px -4px;
                    white-space: normal;
                    text-overflow: initial;
                }

                .select2-selection__choice {
                    @extend .label;
                    float: none;
                    padding-right: 5px;

                    &__remove {
                        float: right;
                        display: block;
                        padding: 0 5px;
                        font-size: 23px;
                        font-weight: normal;

                        @media (min-width: $screen-sm-min) {
                            font-size: 20px;
                        }
                    }
                }

                .select2-selection__clear {
                    margin-top: 4px;
                    margin-right: 0;
                }

                .select2-search--inline {
                    display: inline-block;
                    float: none;
                    // add margins removed in the container
                    margin-left: 4px;
                    margin-bottom: 5px;

                    .select2-search__field {
                        margin-top: 0;
                        @include placeholder($placeholder-color);
                    }
                }
            }

            .select2-selection__clear {
                font-weight: normal;

                @media (max-width: $screen-xs-max) {
                    font-size: 23px;
                    line-height: 23px;
                    padding: 6px;
                    margin: 0;
                    width: 35px;
                    height: 35px;
                    text-align: center;
                }

                @media (min-width: $screen-sm-min) {
                    font-size: 20px;
                }
            }

            &.select2-container--open {
                .select2-selection--single {
                    .select2-selection__arrow {
                        b {
                            border-bottom-color: #333;
                        }
                    }
                }

                .select2-dropdown--below {
                    border-top: inherit;
                    border-radius: 0;
                }
            }

            &.select2-container--disabled {
                color: $input-color-disabled;

                .select2-selection--single {
                    background-color: $input-background-color-disabled;
                    cursor: not-allowed;

                    &:focus {
                        box-shadow: none;
                    }

                    .select2-selection__rendered {
                        .select2-selection__placeholder {
                            color: $placeholder-color-disabled;
                        }
                    }
                }

                .select2-selection--multiple {
                    background-color: $input-background-color-disabled;

                    &:focus {
                        box-shadow: none;
                    }

                    .select2-search--inline .select2-search__field {
                        cursor: not-allowed;
                        @include placeholder($placeholder-color-disabled);
                    }

                    .select2-selection__choice {
                        cursor: not-allowed;
                        padding-right: 10px;
                    }

                    .select2-selection__choice__remove {
                        display: none !important;
                    }
                }
            }
        }

        .app-select.loading {
            .select2-container--default {
                .select2-selection--single::after,
                .select2-selection--multiple::after {
                    content: " ";
                    background: url('./../images/loading-small.gif');
                    width: 16px;
                    height: 16px;
                    display: block;
                    position: absolute;
                    top: 7px;
                }

                .select2-selection--single::after {
                    right: 32px;
                }

                .select2-selection--multiple::after {
                    right: 9px;
                }
            }
        }
    }

    form.form {
        .form__label {
            font-weight: normal;

            &__text--required {
                font-weight: 600;
                color: inherit;

                &:after {
                    content: " *";
                    color: $error-color;
                }
            }
        }
    }

    // Attention: The results container does not live within the .form
    .select2-container:not(.select2) {
        padding-top: 3px;

        .select2-dropdown {
            box-shadow: $dropdown-shadow;
            border: 1px solid $dropdown-border;
            border-radius: $dropdown-border-radius;

            .select2-search__field {
                outline: none;
                border: 0;
                @include placeholder($placeholder-color);
            }

            .select2-results {
                &__group, &__option {
                    @media (max-width: $screen-xs-max) {
                        line-height: 24px;
                    }
                }

                &__option {
                    &:not([role=group]):not(.select2-results__message):not(.loading-results) {
                        padding: $dropdown-item-padding;
                    }

                    &[aria-selected=true], &--highlighted {
                        background: $dropdown-link-hover-bg;
                        color: $dropdown-link-hover-color;
                    }

                    &__contact {
                        padding-left: 25px;
                        position: relative;

                        &__icon {
                            position: absolute;
                            left: 0;
                            line-height: inherit;
                        }
                    }
                }
            }
        }
    }

    .image-input {
        position: relative;

        .image-box {
            border-radius: 2 * $input-border-radius;
        }

        &__add {
            background: none;
            color: $placeholder-color;
            font-size: 50px;
            border: 2px dashed $input-border;
            border-radius: 2 * $input-border-radius;
            text-align: center;
            @include transition(color $input-transition, border $input-transition);

            &:hover {
                color: darken($placeholder-color, 10%);
                border-color: darken($input-border, 10%);
            }

            &:focus {
                outline: none;
            }
        }

        &__actions {
            position: absolute;
            top: 10px;
            right: 10px;

            button {
                background: none;
                border: 0;
                padding: 0;
                color: white;
                font-size: 16px;
                width: 26px;
                height: 26px;
                filter: drop-shadow(0 0 1px black);

                &:focus {
                    outline: none;
                }
            }
        }

        input[type=file] {
            display: none;
        }
    }

    .textarea-group {
        &__toolbar {
            background: $input-group-addon-bg;
            padding: $padding-base-vertical $padding-base-horizontal;
            border: 1px solid $input-border;
            border-bottom: 0;
            border-top-left-radius: $input-border-radius;
            border-top-right-radius: $input-border-radius;

            & + textarea {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }

    .file-input {
        &__selection-list {
            list-style-type: none;
            padding: 0;
            margin: 10px 0 0;

            li {
                line-height: 24px;

                div {
                    display: flex;

                    span {
                        margin-right: 5px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}
