@import "colors";

/*border-radius*/
$panel-border-radius: 0px;
$card-border-radius: 2px;
$table-border-radius: 2px;
$field-modification-border-radius: 3px;
$dropdown-border-radius: 3px;
$input-border-radius: 2px;
$note-border-radius: 3px;

/*sizes*/
$desktop-small: 681px;
$desktop: 1025px;

/*transitions*/
$input-transition: 0.3s;
$form-line-open-transition: 1s;
$form-line-close-transition: 0.2s;

$content-shadow: 0 2px 5px 0 $color-content-shadow;
$tab-shadow: $content-shadow;
$table-shadow: $content-shadow;
$card-shadow: $content-shadow;
$input-shadow-focus: $content-shadow;
$modal-shadow: 0 4px 10px 0 $color-modal-shadow;
$mobile-screen-menu-shadow: 0 2px 5px 0 $color-mobile-screen-menu-shadow;
$dropdown-shadow: 0 6px 12px $color-dropdown-shadow;

/*media-queries*/
$mq_mobile: "only screen and (max-width: #{$desktop-small - 1})";
$mq_desktop-small: "only screen and (min-width: #{$desktop-small}) and (max-width: #{$desktop - 1})";
$mq-desktop: "only screen and (min-width: #{$desktop})";
$mq_desktop-any: "only screen and (min-width: #{$desktop-small})";

/*spaces*/
$dropdown-item-padding: 6px 20px;
$space-xxs: 3px;
$space-xs: 6px;
$space-sm: 12px;
$space-md: 24px;
$space-lg: 32px;
$space-xl: 48px;
$space-xxl: 62px;

/*z-indexes*/
$z-index-card-actions: 1;
$z-index-login-right-half: 1;
$z-index-layout-sidebar: 10;
$z-index-layout-header: 20;
$z-index-layout-footer: 15;
$z-index-modal: 1000;
$z-index-mobile-tab-view-handles: 1000;
$z-index-mobile-screen-menu: 1010;

/*bootstrap*/
$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px

$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~12px

$screen-lg: 1440px;

/*menu*/
$menu-bar-height: 56px;

/*footer*/
$footer-height: 50px;
