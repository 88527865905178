@use "sass:math";

// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

@mixin nav-divider($color: #e5e5e5) {
    height: 1px;
    margin: (math.div($line-height-computed, 2) - 1) 0;
    overflow: hidden;
    background-color: $color;
}
