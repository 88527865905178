@mixin circular-button-variant($color, $background, $hover-color) {
    color: $color;
    background: $background;
    border-color: $color;

    @media (min-width: $screen-sm-min) {
        &:not([disabled]):hover {
            color: $hover-color;
            border-color: transparent;
            box-shadow: 0 0 0 3px $hover-color;
        }
    }

    &:active,
    &.active,
    &[aria-expanded="true"] {
        &, &:hover, &:focus {
            border-style: solid;
            border-color: desaturate(lighten($hover-color, 30%), 20%);
            background: desaturate(lighten($hover-color, 30%), 20%);
            color: $background;
            box-shadow: none;
        }
    }

    &.toggled-on, &.toggled-on[disabled] {
        &, &:hover, &:active, &:focus {
            border-style: solid;
            border-color: $hover-color;
            background: $hover-color;
            color: $background;
            box-shadow: none;
        }

        @media (min-width: $screen-sm-min) {
            &:not([disabled]):hover {
                color: $background;
                border-color: $hover-color;
                background: $hover-color;
                box-shadow: 0 0 0 3px $hover-color;
            }
        }
    }

    &[disabled] {
        &, &:hover, &:active, &:focus {
            color: desaturate(lighten($color, 5%), 15%);
            background: $background;
            border-color: desaturate(lighten($color, 5%), 15%);
        }
    }
}

@mixin circular-button-variant-inverted($color, $background, $border) {
    color: $color;
    background: $background;
    border-color: $border;

    @media (min-width: $screen-sm-min) {
        &:not([disabled]):hover {
            color: $background;
            background: $color;
            border-color: transparent;
            box-shadow: 0 0 0 3px $border;
        }
    }

    &:active,
    &.active,
    &[aria-expanded="true"] {
        &, &:hover, &:focus {
            border-style: solid;
            border-color: desaturate(lighten($background, 30%), 20%);
            background: desaturate(lighten($background, 30%), 20%);
            color: $color;
            box-shadow: none;
        }
    }

    &.toggled-on {
        &, &:hover, &:active, &:focus {
            border-style: solid;
            border-color: $background;
            background: $background;
            color: $color;
            box-shadow: none;
        }
    }

    &[disabled] {
        &, &:hover, &:active, &:focus {
            color: $color;
            background: desaturate(lighten($background, 15%), 15%);
            border-color: desaturate(lighten($border, 15%), 15%);
        }
    }
}

.owm {
    .btn {
        .fal, .far, .fas {
            margin-right: 0 !important;
        }

        &.btn-nochrome {
            color: $icon-color;
            border: none;
            background: none;
            padding: 0;
            font-size: 14px;
            margin-right: 10px;
            vertical-align: baseline;

            &:hover, &:active, &:focus, &.active {
                border: none;
                background: none;
                outline: none;
                box-shadow: none;
            }

            &.active, &:hover:not([disabled]) {
                color: $icon-color-hover;
            }

            &[disabled] {
                opacity: 0.5;
                cursor: not-allowed;
            }

            &.btn-icon-toggle {
                margin-right: 0;
                line-height: 14px;

                .fal, .far, .fas {
                    width: 22px;
                    height: 22px;
                    line-height: 14px;
                    font-size: 14px;
                    border: 1px solid transparent;
                    border-radius: 50%;
                    padding: 3px;

                    //&.fa-chevron-up,
                    //&.fa-chevron-down,
                    //&.fa-comment,
                    //&.fa-comment-o {
                    //    font-size: 13px;
                    //}
                    //
                    //&.fa-envelope,
                    //&.fa-calendar,
                    //&.fa-camera {
                    //    font-size: 12px;
                    //}
                    //
                    //&.fa-camera {
                    //    padding-top: 4px;
                    //    padding-bottom: 2px;
                    //}
                }

                .btn-icon-toggle__active-icon {
                    display: none;
                }

                .btn-icon-toggle__inactive-icon {
                    display: inline-block;
                }

                &[disabled] {
                    opacity: 1;
                    cursor: default;
                }

                &:hover:not([disabled]) {
                    .fal, .far, .fas {
                        color: $icon-toggle-bg-active;
                        border-color: $icon-toggle-bg-active;
                    }
                }

                &.active {
                    .fal, .far, .fas {
                        color: $icon-toggle-color-active;
                        border-color: $icon-toggle-bg-active;
                        background: $icon-toggle-bg-active;
                    }

                    &:hover:not([disabled]) {
                        .fal, .far, .fas {
                            color: $icon-toggle-color-active;
                            border-color: $icon-toggle-bg-active;
                        }
                    }

                    .btn-icon-toggle__active-icon {
                        display: inline-block;
                    }

                    .btn-icon-toggle__inactive-icon {
                        display: none;
                    }
                }

                &--preview:not([disabled]) {
                    &:hover {
                        .btn-icon-toggle__active-icon {
                            display: inline-block;
                        }

                        .btn-icon-toggle__inactive-icon {
                            display: none;
                        }
                    }

                    &.active:hover {
                        .btn-icon-toggle__active-icon {
                            display: none;
                        }

                        .btn-icon-toggle__inactive-icon {
                            display: inline-block;
                        }
                    }
                }

                &--warning {
                    &:hover:not([disabled]) {
                        .fal, .far, .fas {
                            color: $icon-toggle-bg-warning-active;
                            border-color: $icon-toggle-bg-warning-active;
                        }
                    }

                    &.active, &.active:hover:not([disabled]) {
                        .fal, .far, .fas {
                            color: $icon-toggle-color-warning-active;
                            border-color: $icon-toggle-bg-warning-active;
                            background: $icon-toggle-bg-warning-active;
                        }
                    }
                }

                &--info {
                    &:hover:not([disabled]) {
                        .fal, .far, .fas {
                            color: $icon-toggle-bg-info-active;
                            border-color: $icon-toggle-bg-info-active;
                        }
                    }

                    &.active {
                        .fal, .far, .fas {
                            color: $icon-toggle-color-info-active;
                            border-color: $icon-toggle-bg-info-active;
                            background: $icon-toggle-bg-info-active;
                        }
                    }
                }
            }
        }
    }

    .btn-group {
        &.open {
            .dropdown-toggle {
                box-shadow: none;
            }
        }
    }

    .btn-bar {
        margin-top: 20px;
    }

    .centered-circular-buttons-container {
        position: relative;
    }

    .centered-circular-buttons {
        position: absolute;
        top: 50%;

        &, &.circular-button {
            margin-top: -15px; // 30px/2
        }

        @media (min-width: $screen-sm-min) {
            &--small {
                &, &.circular-button {
                    margin-top: -12px; // 24px/2
                }
            }
        }
    }

    .btn.active > .fa-star {
        color: #f39c12;
    }

    .circular-button {
        width: 30px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        background: none;
        border: 1px dashed transparent;
        border-radius: 15px;
        padding: 0;
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        @include circular-button-variant($icon-color, white, $icon-color-hover);

        @media (max-width: $screen-xs-max) {
            border-style: solid;
            width: 36px;
            height: 36px;
            line-height: 34px;
            border-radius: 18px;
            font-size: 16px;
        }

        &:hover, &:active, &:focus {
            outline: none;
        }

        &--solid {
            border-style: solid;
        }

        &--success {
            @include circular-button-variant-inverted(white, $success-color, $success-color);
        }

        &--accent {
            @include circular-button-variant-inverted(white, $accent-color, $accent-color);
        }

        &--danger {
            @include circular-button-variant-inverted(white, $error-color, $error-color);
        }

        &--warning {
            @include circular-button-variant($icon-color, white, $icon-color-warning);
        }

        @media (min-width: $screen-sm-min) {
            &--small {
                height: 24px;
                width: 24px;
                line-height: 22px;
                border-radius: 12px;
                font-size: 12px;
            }
        }

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .button-group {
        margin: 0 -5px;
        @include clearfix;

        &--left {
            float: left;
        }

        &--right {
            float: right;
        }

        .btn {
            margin: 0 5px;
        }
    }

    .filter-button {
        &__item--invalid {
            color: $error-color;
        }

        .filter-button__clear {
            z-index: 100;

            &:hover {
                color: red;
            }
        }
    }

    .filter-button__active {
        .filter-button__current-item {
            background-color: $brand-warning;
        }
    }

    .dropdown-menu {
        box-shadow: $dropdown-shadow;
        border-color: $dropdown-border;
        border-radius: $dropdown-border-radius;

        > li > a {
            padding: $dropdown-item-padding;
            cursor: pointer;

            &.text-danger {
                color: $brand-danger;
            }

            &.disabled {
                color: $text-muted;
            }

            @media (max-width: $screen-xs-max) {
                line-height: 24px;
            }
        }
    }

    .responsive-img {
        &:hover {
            .popover {
                opacity: 1;
            }
        }

        .popover {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: .3s ease;
            background-color: red;

            .icon {
                color: white;
                font-size: 100px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                text-align: center;
            }
        }
    }

}
