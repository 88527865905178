html {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
}

// This style must be defined outside of .owm, otherwise we overwrite a.btn styles
a {
    color: $text-color;
    cursor: pointer; // even if no href
}

.owm {
    height: 100%;
    width: 100%;
    font-family: "Open Sans", "Trebuchet MS", Arial, Helvetica, sans-serif;

    @media (max-width: $screen-xs-max) {
        font-size: 14px;
    }

    @media (min-width: $screen-sm-min) {
        background: $content-bg;
    }

    h1, h2, h3, h4, h5 {
        font-weight: 600;
        color: $headline-color;
        margin-top: 0;
    }

    h1 {
        font-weight: 400;
        text-shadow: 1px 1px rgba(119, 42, 38, 0.2);
    }

    h4 {
        font-size: 16px;
    }

    a.icon-link {
        display: flex;
        app-icon {
            display: inline-block;
            margin-right: 7px;
        }
    }

    a.colored, p a, .checkbox__label a, ol.terms li a, .form__value__text-control a {
        color: $link-color;
    }

    code {
        font-size: 13px;
        padding: 2px 5px;
        background: $code-bg;
    }

    ol.terms {
        list-style-type: none;
        padding-left: 0; // match the text above

        li {
            counter-increment: bullet;
            padding-left: 25px;
            margin-bottom: 10px;
            position: relative;

            &:before {
                content: "(" counter(bullet,lower-latin) ")";
                position: absolute;
                left: 0;
            }
        }
    }

    strong, .strong {
        font-weight: 600;
    }

    .small {
        font-size: 12px;
        color: #6a737d;

        .svg-inline--fa {
            color: inherit;
        }

        a {
            &, &:hover, &:active, &:focus {
                color: inherit;
            }
        }
    }

    .placeholder {
        color: $placeholder-color;

        a,
        .small,
        .svg-inline--fa {
            color: $placeholder-color;
        }
    }

    .row.row--vertical-padding {
        margin-top: -10px;
        margin-bottom: -10px;
    }

    .row.row--vertical-padding > *[class^="col-"] {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .text-light {
        font-weight: normal;
    }

    .text-success {
        color: $success-color;
    }

    .text-error {
        color: $error-color;
    }

    .text-brown {
        color: $brown4;
    }

    .text-preview {
        border: 2px solid lighten($border-light, 7%);
        padding: 5px;
    }

    .flag {
        margin-right: 5px;
    }

    .badge {
        font-size: 10px;
    }

    .image {
        margin-bottom: 10px;

        img {
            border: 2px solid $image-border;
            border-radius: 2px;
            padding: 10px;
            margin-bottom: 5px;
        }

        &__caption {
            color: $placeholder-color;
        }
    }

    .vertical-center {
        display: inline-block;
        vertical-align: middle;
    }

    .padding {
        &-top {
            &-3xl {
                padding-top: 50px !important;
            }
        }
    }

    .block {
        display: block;
    }

    .margin {
        &-none {
            margin-bottom: 0 !important;
        }

        &-sm {
            margin-bottom: 5px !important;
        }

        &-md {
            margin-bottom: 10px !important;
        }

        &-lg {
            margin-bottom: 20px !important;
        }

        &-xl {
            margin-bottom: 30px !important;
        }

        &-xxl {
            margin-bottom: 40px !important;
        }

        &-top {
            &-none {
                margin-top: 0 !important;
            }

            &-sm {
                margin-top: 5px !important;
            }

            &-md {
                margin-top: 10px !important;
            }

            &-lg {
                margin-top: 20px !important;
            }

            &-xl {
                margin-top: 30px !important;
            }

            &-xxl {
                margin-top: 40px !important;
            }
        }

        &-left {
            &-none {
                margin-left: 0 !important;
            }

            &-sm {
                margin-left: 5px !important;
            }

            &-md {
                margin-left: 10px !important;
            }

            &-lg {
                margin-left: 20px !important;
            }

            &-xl {
                margin-left: 30px !important;
            }

            &-xxl {
                margin-left: 40px !important;
            }
        }

        &-right {
            &-none {
                margin-right: 0 !important;
            }

            &-sm {
                margin-right: 5px !important;
            }

            &-md {
                margin-right: 10px !important;
            }

            &-lg {
                margin-right: 20px !important;
            }

            &-xl {
                margin-right: 30px !important;
            }

            &-xxl {
                margin-right: 40px !important;
            }
        }
    }

    .visible-xs-table-cell,
    .visible-sm-table-cell,
    .visible-md-table-cell,
    .visible-lg-table-cell {
        display: none !important;
    }

    .visible-xs-table-cell {
        @media (max-width: $screen-xs-max) {
            display: table-cell !important;
        }
    }

    .visible-sm-table-cell {
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            display: table-cell !important;
        }
    }

    .visible-md-table-cell {
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            display: table-cell !important;
        }
    }

    .visible-lg-table-cell {
        @media (min-width: $screen-lg-min) {
            display: table-cell !important;
        }
    }
}
