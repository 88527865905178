.owm {
    .login {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        height: 100%;
        padding-bottom: $footer-height;
        justify-content: center;

        &__left-half {
            background: url(/assets/images/login-512.jpg) no-repeat center;
            background-size: cover;

            @media (max-width: 768px) {
                position: absolute;
                left: 0;
            }
        }

        &__right-half {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            width: 50%;
            z-index: $z-index-login-right-half;

            @media (max-width: 768px) {
                width: 90%;
            }
        }

        &__panel-wrapper {
            max-width: 520px;
            display: flex;
            flex-direction: column;
        }

        &__panel {
            background-color: #fff;
            border-radius: 2px;
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .05);

            &__body {
                @media (max-width: 768px) {
                    margin: 20px;
                }
            }
        }

        &__logo {
            margin-bottom: 40px;
            max-width: 250px;
            max-height: 70px;
        }

        a {
            color: $link-color;

            &:hover {
                color: $link-hover-color;
            }
        }

        .right-margin {
            margin-right: 10px;
        }

        &__form {
            &__line {
                margin-bottom: 20px;
            }

            &__error {
                margin-top: 5px;
                color: $error-color;
            }
        }

        .app__links {
            padding-top: 16px;
            padding-bottom: 4px;

            .app__link {
                .logo {
                    height: 20px;
                    vertical-align: top;
                    margin-right: $space-xxs;
                }

                &:not(:last-of-type) {
                    margin-bottom: 16px;
                }
            }
        }

        @media (min-width: 768px) {
            .login__left-half {
                width: 50%;
            }

            .login__panel__body {
                margin: 40px;
            }
        }

        @media (min-height: 513px), (min-width: 703px) {
            .login__left-half {
                background-image: url(/assets/images/login-1024.jpg);
            }
        }

        @media (min-height: 1025px), (min-width: 1405px) {
            .login__left-half {
                background-image: url(/assets/images/login-2048.jpg);
            }
        }

        @media (min-height: 2049px), (min-width: 2811px) {
            .login__left-half {
                background-image: url(/assets/images/login-4096.jpg);
            }
        }
    }
}
