.owm {
    .confirmation-page-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: $footer-height;
        align-items: center;
        background: $content-bg;
        overflow: auto;

        .confirmation-page-top-spacing {
            flex: 1;
            max-height: 130px;
            width: 100%;

            @media (max-width: 400px) {
                max-height: 10%;
            }
        }

        .confirmation-page {
            max-width: 992px;
            width: 100%;
            padding: 0 $space-lg;
            box-sizing: border-box;

            .confirmation-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                border-radius: 2px;
                box-shadow: 0 4px 4px 0 #E9E2E2;
                padding: 52px 20%;

                @media (max-width: 768px) {
                    padding-left: 10%;
                    padding-right: 10%;
                }

                @media (max-height: 700px)  {
                    padding-top: 5%;
                    padding-bottom: 5%;
                }

                .call-to-action__icon {
                    margin-bottom: $space-lg;
                }

                p {
                    text-align: center;
                    max-width: 100%;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
