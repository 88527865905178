@use "sass:math";

// Grid system
//
// Generate semantic grid columns with these mixins.

// Centered container element
@mixin container-fixed($gutter: $grid-gutter-width) {
    margin-right: auto;
    margin-left: auto;
    padding-left: floor(math.div($gutter, 2));
    padding-right: ceil(math.div($gutter, 2));
    @include clearfix;
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
    margin-left: ceil(math.div($gutter, -2));
    margin-right: floor(math.div($gutter, -2));
    @include clearfix;
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    float: left;
    width: percentage(math.div($columns, $grid-columns));
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
}

@mixin make-xs-column-offset($columns) {
    margin-left: percentage(math.div($columns, $grid-columns));
}

@mixin make-xs-column-push($columns) {
    left: percentage(math.div($columns, $grid-columns));
}

@mixin make-xs-column-pull($columns) {
    right: percentage(math.div($columns, $grid-columns));
}

// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);

    @media (min-width: $screen-sm-min) {
        float: left;
        width: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-sm-column-offset($columns) {
    @media (min-width: $screen-sm-min) {
        margin-left: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-sm-column-push($columns) {
    @media (min-width: $screen-sm-min) {
        left: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-sm-column-pull($columns) {
    @media (min-width: $screen-sm-min) {
        right: percentage(math.div($columns, $grid-columns));
    }
}

// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: math.div($gutter, 2);
    padding-right: math.div($gutter, 2);

    @media (min-width: $screen-md-min) {
        float: left;
        width: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-md-column-offset($columns) {
    @media (min-width: $screen-md-min) {
        margin-left: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-md-column-push($columns) {
    @media (min-width: $screen-md-min) {
        left: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-md-column-pull($columns) {
    @media (min-width: $screen-md-min) {
        right: percentage(math.div($columns, $grid-columns));
    }
}

// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
    position: relative;
    min-height: 1px;
    padding-left: math.div($gutter, 2);
    padding-right: math.div($gutter, 2);

    @media (min-width: $screen-lg-min) {
        float: left;
        width: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-lg-column-offset($columns) {
    @media (min-width: $screen-lg-min) {
        margin-left: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-lg-column-push($columns) {
    @media (min-width: $screen-lg-min) {
        left: percentage(math.div($columns, $grid-columns));
    }
}

@mixin make-lg-column-pull($columns) {
    @media (min-width: $screen-lg-min) {
        right: percentage(math.div($columns, $grid-columns));
    }
}
