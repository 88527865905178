.owm {
    .page {
        @media (min-width: $screen-md-min) {
            min-height: 500px;
        }

        &__header {
            @include clearfix;

            h1 {
                float: left;
                font-size: 24px;
                margin: 0 0 20px;
                line-height: 1.5em;
            }
        }

        &__hero {
            font-size: 16px;
            margin-bottom: 30px;
            line-height: 1.6em;
        }
    }
}