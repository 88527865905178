.owm {
    .call-to-action {
        text-align: center;
        padding: 10px;

        &__icon {
            width: 120px;
            margin-bottom: 20px;
        }

        &__title {
            font-size: 18px;
            margin-bottom: 10px;
        }

        &__description {
            margin: 0 auto 20px;
            max-width: 600px;
        }
    }
}