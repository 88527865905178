$space-footer-outside: 24px;
$space-between-elements: $space-lg;

.owm {
    .layout__footer {
        position: fixed;
        bottom: 0;
        z-index: $z-index-layout-footer;
        width: 100%;

        footer {
            display: flex;
            background-color: $footer-bg;
            height: $footer-height;
            justify-content: space-between;
            align-items: center;
            padding-left: $space-footer-outside;
            padding-right: $space-footer-outside;

            color: $header-bg;
            font-size: $font-size-base;
            white-space: nowrap;

            gap: $space-between-elements;

            a {
                text-decoration: none;
                color: $header-bg;

                &:hover {
                    color: $header-bg !important;
                }
            }

            section {
                display: flex;
                align-items: center;
                flex: 1;
                gap: $space-between-elements;
            }

            .webmozarts {
                justify-content: center;

                a {
                    display: flex;
                    align-items: center;

                    .logo {
                        height: 22.14px;
                        width: 30.15px;
                        margin-right: $space-sm;
                        margin-left: $space-sm;
                    }

                    .text {
                        width: 94px;
                        height: 12px;
                    }
                }
            }

            .links {
                justify-content: flex-end;

                nav {
                    display: flex;
                    gap: $space-between-elements;
                }
            }
        }
    }
}
