/*ci-colors*/
$red-logo: #cd061e;
$primary-yellow: #fdf894;

$brown1: #4a1e28;
$brown2: #572a34;
$brown3: #6f444a;
$brown4: #927275;
$brown5: #97787a;
$brown6: #ab9091;
$brown7: #c0acac;
$brown8: #ded3d2;

$red1: #8a0d3e;
$red2: #a14460;
$red3: #a3495d;
$red4: #ae606f;
$red5: #c69198;
$red6: #cc9ca2;
$red7: #dfc0c3;
$red8: #ecd9da;

$green0: #006847;
$green1: #227c61;
$green2: #35876f;
$green3: #4d967f;
$green4: #66a491;
$green5: #7eb2a2;
$green6: #99c3b6;
$green7: #b7d4cc;
$green8: #cbe1d9;

$yellow1: #e1ab1b;
$yellow2: #e5b533;
$yellow3: #e8be4d;
$yellow4: #ebc766;
$yellow5: #eed07f;
$yellow6: #f1d894;
$yellow7: #f5e3b2;
$yellow8: #f6e5b8;

$primary-contrast-color: darken($primary-yellow, 50%);
$primary-color: saturate($primary-contrast-color, 40%);
$success-color: saturate($green3, 40%);
$accent-color: saturate($yellow3, 40%);
$error-color: saturate($red3, 40%);
$warning-color: saturate($yellow3, 40%);

$winery-color: $green2;
$company-color: #4d6a96;
$person-color: $red2;

/*images-colors*/
$header-bg: white;
$footer-bg: #222428;
$menu-bg: $primary-yellow;
$menu-item-bg: $menu-bg;
$menu-item-bg-hover: desaturate(lighten($menu-bg, 10%), 30%);
$content-bg: lighten($brown8, 10%);
$table-bg: white;
$table-bg-even: $table-bg;
$table-bg-odd: lighten($content-bg, 2%);
$table-bg-selected: lighten($accent-color, 20%);
$tab-bg: white;
$modal-bg: white;
$modal-overlay-bg: rgba(0, 0, 0, 0.4);
$modal-overlay-fallback-bg: rgb(0, 0, 0);
$field-modification-added-bg: $green8;
$field-modification-removed-bg: $red8;
$badge-bg: $brown8;
$badge-bg-active: $primary-color;
$label-bg: $content-bg;
$label-danger-bg: $error-color;
$accordion-item-expanded-header-bg: $content-bg;
$icon-toggle-bg-active: $primary-color;
$icon-toggle-bg-warning-active: $yellow4;
$icon-toggle-bg-info-active: $green4;
$panel-bg: white;
$panel-language-switcher-bg: $table-bg-odd;
$input-bg: white;
$input-arrow-bg: $content-bg;
$input-group-addon-bg: lighten($content-bg, 2%);
$card-bg: white;
$code-bg: $content-bg;
$dropdown-link-hover-bg: $content-bg;
$mobile-screen-red-menu-bg: $primary-color;
$step-done-bg: $success-color;
$step-active-bg: $accent-color;
$step-inactive-bg: $brown6;
$step-success-bg: $success-color;
$step-danger-bg: $error-color;
$note-success-end-bg: lighten($success-color, 35%);
$note-success-start-bg: lighten($note-success-end-bg, 10%);
$note-info-end-bg: lighten($accent-color, 15%);
$note-info-start-bg: lighten($note-info-end-bg, 10%);
$note-danger-end-bg: saturate(lighten($error-color, 30%), 20%);
$note-danger-start-bg: lighten($note-danger-end-bg, 10%);

/*font-colors*/
$text-color: #333333;
$link-color: $primary-color;
$headline-color: #26222A;
$placeholder-color: #beb5b1;
$placeholder-color-disabled: lighten($placeholder-color, 10%);
$icon-color: darken($brown8, 10%);
$icon-color-hover: $primary-color;
$icon-color-strong: desaturate($brown6, 30%);
$icon-color-success: $success-color;
$icon-color-danger: $error-color;
$icon-color-warning: $warning-color;
$icon-toggle-color-active: white;
$icon-toggle-color-warning-active: white;
$icon-toggle-color-info-active: white;
$field-modification-added-icon-color: $green5;
$field-modification-removed-icon-color: $red5;
$input-color-disabled: #777;
$input-background-color-disabled: #fbf9f9;
$fieldset-heading-color: $placeholder-color;
$lightest: #ffffff;
$darkest: #000000;
$star-color: $icon-color;
$star-color-active: $icon-color-warning;
$avatar-unknown-color: desaturate($brown8, 10%);
$menu-color: contrast($menu-bg);
$dropdown-link-hover-color: $text-color;
$mobile-screen-red-menu-color: white;
$checkbox-color: $brown4;
$checkbox-checked-color: $brown4;
$checkbox-hover-color: $primary-color;
$checkbox-disabled-color: $icon-color;
$step-done-color: white;
$step-active-color: black;
$step-inactive-color: white;
$step-success-color: white;
$step-danger-color: white;
$note-success-color: darken($success-color, 40%);
$note-info-color: darken($accent-color, 50%);
$note-danger-color: darken($error-color, 40%);

/*border-colors*/
$border-light: lighten($brown8, 5%);
$input-border: $border-light;
$input-border-hover: $primary-color;
$input-border-invalid: $error-color;
$tab-border: $border-light;
$tab-border-active: $primary-color;
$table-row-border: $border-light;
$modal-header-border: $border-light;
$card-border: $border-light;
$card-top-border: $primary-color;
$panel-border: $border-light;
$panel-top-border: $border-light;
$field-modification-added-border: $green7;
$field-modification-removed-border: $red7;
$header-border: $header-bg;
$header-border-success: saturate($green4, 90%);
$header-border-error: $error-color;
$query-builder-border: $primary-color;
$image-border: $content-bg;
$dropdown-border: $border-light;
$label-border: $border-light;
$label-danger-border: darken($error-color, 5%);
$note-success-border: lighten($note-success-start-bg, 3%);
$note-info-border: lighten($note-info-start-bg, 3%);
$note-danger-border: lighten($note-danger-start-bg, 3%);

/*item-colors*/
$evenColor: #f9f9f9;
$oddColor: #ffffff;
$pileBgColor: #f5f0ec;
$item-hilight: $primary-color;

/*btn-colors*/
$btn-cancel-color: #999999;
$btn-link-color: #25AAE1;

/*shadows*/
$color-content-shadow: rgba(0, 0, 0, 0.05);
$color-modal-shadow: rgba(0, 0, 0, 0.3);
$color-mobile-screen-menu-shadow: rgba(0, 0, 0, 0.2);
$color-dropdown-shadow: rgba(0, 0, 0, 0.175);

/*bootstrap*/
$brand-primary: $primary-color;
$brand-success: $success-color;
$brand-danger: $error-color;
$brand-warning: $warning-color;

$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;
